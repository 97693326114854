import React, {FC, useCallback, useEffect, useState} from 'react';
import {Badge, Box, Button, Flex, Heading, HStack, Select, useToast, VStack} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../DataTable/DataTable";
import { PaymentData } from '../../Types/PaymentData';
import { OnPageChangeEvent } from '../../Types/OnPageChangeEvent';
import ReactPaginate from 'react-paginate';
import { apiService } from '../../Services/ApiService';
import { logger } from '../../Logger';
import { formatDateTime, getDateFormat } from '../../utils/DateTimeFormat';


interface ReportProps {}


const Report: FC<ReportProps> = () => {
    const tmpDate = new Date();
    tmpDate.setDate(1);
    const [locale] = useState<string>(navigator.language);

    const [startDate, setStartDate] = useState<Date | undefined>(tmpDate);
    const [endDate, setEndDate] = useState<Date | undefined>(new Date());

    const toast = useToast();
    const [payments, setPayments] = useState<PaymentData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize] = useState(10); // Number of items per page
    const columnHelper = createColumnHelper<PaymentData>();
    const columns = [
        columnHelper.accessor("id", {
            cell: (info) => info.getValue(),
            header: "ID"
        }),
        columnHelper.accessor("programName", {
            cell: (info) => info.getValue(),
            header: "Title"
        }),
        columnHelper.accessor("amount", {
            cell: (info) => <><Badge colorScheme="green" >{info.getValue()} EUR</Badge></>,
            header: "Amount"
        }),
        columnHelper.accessor("status", {
            cell: (info) => info.getValue(),
            header: "Status"
        }),
        columnHelper.accessor("paymentDate", {
            cell: (info) => {
                const val = info.getValue();
                if (val !== undefined) {
                    const date = new Date(val);
                    return formatDateTime(locale, date)
                } else {
                    return "";
                }
            },
            header: "Payment Date"
        }),
    ];

    const fetchPayments = useCallback(() => {
        if (endDate != null && startDate != null) {
            apiService.getPayments(endDate, startDate, {page: currentPage, pageSize: pageSize}).then((response) => {
                setPayments(response.data.content);
                setTotalPages(response.data.totalPages);
            }).catch(() => {
                toast({
                    title: 'Fetching payments for user error.',
                    description: '',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
        }
    }, [currentPage, endDate, pageSize, startDate, toast]);

    useEffect(() => {
        fetchPayments();
    }, [fetchPayments]);

    const handlePageChange = (selected: OnPageChangeEvent) => {
        setCurrentPage(selected.selected);
    };


    return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
        <Box>
            <Heading as='h1' size="2xl">Report</Heading>
        </Box>
        <Box my="4">
            <HStack alignItems="end">
                <VStack mr="16" alignItems="start">
                    <Heading as="h2" size="xl" mb="4" fontWeight="semibold">Gym</Heading>
                    <Select
                        bg="white"
                        border={"0"}
                        boxShadow={"xl"}
                        disabled={true}
                    >
                        <option value='option1'>All</option>
                        <option value='option2'>LocationOne</option>
                        <option value='option3'>LocationTwo</option>
                        <option value='option4'>LocationThree</option>
                    </Select>
                </VStack>
                <VStack alignItems="start">
                    <Heading as="h2" size="xl" mb="4" fontWeight="semibold">Date range</Heading>
                    <DatePicker
                    dateFormat={getDateFormat(locale)}
                        selectsRange={true}
                        yearDropdownItemNumber={100}
                        showYearDropdown
                        scrollableYearDropdown
                        showIcon
                        startDate={startDate}
                        endDate={endDate}
                        selected={startDate}
                        onChange={(update) => {
                            const [start, end] = update;
                            setStartDate(start instanceof Date ? start : undefined);
                            setEndDate(end instanceof Date ? end : undefined);
                        }}
                        withPortal
                        shouldCloseOnSelect={false}
                    />
                </VStack>
                <Box >
                    <Button
                        width="1xs"
                        mt="4"
                        bg="facebook.400"
                        _hover={{ bg:"facebook.500" }}
                        color="white"
                        type="submit"
                    >Export XML</Button>
                </Box>
            </HStack>
        </Box>
        <Box>
        <Box>
                <DataTable columns={columns} data={payments} />
            </Box>
            <Box>
                <ReactPaginate
                    className='pagination'
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </Box>
        </Box>
    </Flex>
)};

export default Report;
