export const getDateFormat = (locale: string) => {
    const format = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    
      // Format today's date to get the order of parts
      const parts = format.formatToParts(new Date());
      
      // Map parts to the correct date-fns tokens (dd/MM/yyyy)
      return parts.map(part => {
        switch (part.type) {
          case 'day':
            return 'dd'; // Use date-fns token for day
          case 'month':
            return 'MM'; // Use date-fns token for month
          case 'year':
            return 'yyyy'; // Use date-fns token for year
          default:
            return part.value; // For separators like "/" or "-"
        }
      }).join(''); // Join parts into a string format
  };

  export const getDateTimeFormat = (locale: string) => {
    const format = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Use 24-hour format
      });
    
      // Format today's date to get the order of parts
      const parts = format.formatToParts(new Date());
      
      // Map parts to the correct date-fns tokens (dd/MM/yyyy)
      return parts.map(part => {
        switch (part.type) {
          case 'day':
            return 'dd'; // Use date-fns token for day
          case 'month':
            return 'MM'; // Use date-fns token for month
          case 'year':
            return 'yyyy'; // Use date-fns token for year
          default:
            return part.value; // For separators like "/" or "-"
        }
      }).join(''); // Join parts into a string format
  };

export const formatDate = (locale: string, date: Date) => {
    const format = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

    return format.format(date);
}

export const formatDateTime = (locale: string, date: Date) => {
    const format = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Use 24-hour format
      });

    return format.format(date);
}