import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Table, TableContainer, Tbody, Td, Th, Thead, Tr,
    Badge,
    useStyleConfig,
    useTab,
    useToast
} from "@chakra-ui/react";
import ProfileCard from "../ProfileCard/ProfileCard";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import DatePicker from "react-datepicker";
import CustomInputField from "../CustomInputField/CustomInputField";
import { CheckCircleIcon } from "@chakra-ui/icons";
import AttendanceTable from "../AttendanceTable/AttendanceTable";
import { useParams } from 'react-router-dom';
import { ProfileData } from '../../Types/ProfileData';
import { apiService } from '../../Services/ApiService';
import { AxiosError } from 'axios';
import SubscriptionsByUser from '../Subscriptions/SubscriptionsByUser';
import { PaymentTableByUser } from '../Payment/PaymentTableByUser';
import { getDateFormat } from '../../utils/DateTimeFormat';


interface MemberDetailsProps {
    name?: string,
    surename?: string,
    gender?: string,
    brithDate?: Date,
    emailAddress?: string,
    contact?: string,
}


const MemberDetails: FC<MemberDetailsProps> = () => {
    const { id } = useParams();
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userProfile, setUserProfile] = useState<ProfileData>({})
    const [locale] = useState<string>(navigator.language);

    const fetchUserProfile = useCallback(() => {
        apiService.getProfileByUserId(Number(id)).then((response) => {
            setUserProfile(response.data);
        }).catch(() => {

        });
    }, [id]);

    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]);

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserProfile({
            ...userProfile,
            [name]: value,
        });
    };

    const onSubmitUpdate = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        apiService.updateProfileForUser(userProfile, Number(id))
        .then((response) => {
            toast({
                title: 'Profile update successful.',
                description: '',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        })
        .catch((error: AxiosError) => {
            toast({
                title: 'Profile update error.',
                description: '',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    }

    return (
        <div data-testid="MemberDetails">
            <Box display="block" p="8" pt={16} bg="background.50" minHeight="100vh">
                <Box>
                    <ProfileCard text={`${userProfile?.name} ${userProfile?.surname}`} email={userProfile?.email !== undefined ? userProfile.email : ""} role={""} isUserProfileCard={false} />
                </Box>
                <Box py="16" pr="4">
                    <Tabs bg="white" isFitted>
                        <TabList mb='1em' p="4" justifyContent="space-between">
                            <Tab>Details</Tab>
                            {/* <Tab>Attendance</Tab> */}
                            <Tab>Subscriptions</Tab>
                            <Tab>Payment History</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box bg="white" h="100%" p="4">
                                <form onSubmit={onSubmitUpdate}>
                                    <Flex flexDirection="row" p="4" justifyContent="space-around">
                                        <CustomInputField id={"name"} label={"NAME"} input={userProfile?.name} onChange={handleFormChange} />
                                        <CustomInputField id={"surname"} label={"SURNAME"} input={userProfile?.surname} onChange={handleFormChange} />
                                    </Flex>
                                    <Flex p="4">
                                        <FormControl>
                                            <FormLabel color="#868686" fontWeight="normal">BIRTH DATE</FormLabel>
                                            <DatePicker yearDropdownItemNumber={100} showYearDropdown scrollableYearDropdown showIcon selected={userProfile?.birthday === undefined ? new Date() : new Date(userProfile?.birthday)} onChange={date => {
                                                        setUserProfile({
                                                            ...userProfile,
                                                            birthday: date?.toISOString(),
                                                        });
                                        }} dateFormat={getDateFormat(locale)}/>
                                        </FormControl>
                                    </Flex>
                                    <Flex p="4">
                                        <CustomInputField id={"address"} label={"ADDRESS"} input={userProfile?.address} onChange={handleFormChange} />
                                        <CustomInputField id={"city"} label={"CITY"} input={userProfile?.city} onChange={handleFormChange} />
                                    </Flex>
                                    <Flex p="4">
                                        <CustomInputField id={"zip"} label={"ZIP"} input={userProfile?.zip} onChange={handleFormChange} />
                                        <CustomInputField id={"country"} label={"COUNTRY"} input={userProfile?.country} onChange={handleFormChange} />
                                    </Flex>
                                    <Flex p="4">
                                        <CustomInputField type="email" id={"email"} label={"EMAIL ADDRESS"} input={userProfile?.email} onChange={handleFormChange} />
                                        <CustomInputField id={"phone"} label={"PHONE"} input={userProfile?.phone} onChange={handleFormChange} />
                                    </Flex>

                                    <Box pl="4">
                                        <Button
                                            width="1xs"
                                            mt="4"
                                            bg="success.50"
                                            _hover={{ bg: "success.100" }}
                                            color="white"
                                            type="submit"
                                            isLoading={isSubmitting}
                                        >Update</Button>
                                    </Box>
                                    </form>
                                </Box>
                            </TabPanel>
                            {/* <TabPanel>
                                <AttendanceTable id={Number(id)} />
                            </TabPanel> */}
                            <TabPanel>
                                <SubscriptionsByUser id={Number(id)} />
                            </TabPanel>
                            <TabPanel>
                                <PaymentTableByUser id={Number(id)} />
                                {/* <TableContainer>
                                    <Table variant='striped' colorScheme="blackAlpha">
                                        <Thead bg="blackAlpha.800">
                                            <Tr>
                                                <Th textColor="white">Datum izdaje</Th>
                                                <Th textColor="white">Št. računa</Th>
                                                <Th textColor="white">Znesek</Th>
                                                <Th textColor="white">Datum plačila</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>01.01.2024</Td>
                                                <Td><Badge colorScheme='green'>ST20022</Badge></Td>
                                                <Td>70.0 €</Td>
                                                <Td>12.01.2024</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>01.01.2024</Td>
                                                <Td><Badge colorScheme='red'>ST20022</Badge></Td>
                                                <Td>70.0 €</Td>
                                                <Td></Td>
                                            </Tr>
                                            <Tr>
                                                <Td>01.01.2024</Td>
                                                <Td><Badge colorScheme='green'>ST20022</Badge></Td>
                                                <Td>70.0 €</Td>
                                                <Td>12.01.2024</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer> */}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </div>
    );
}

export default MemberDetails;
