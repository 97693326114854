import { Avatar, Box, Button, Flex } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProgramCardInf {
    itemId: number,
    picUrl: string,
    title: string,
    type: string,
    price: number,
    currency: string,
}

export const ProgramCard: FC<ProgramCardInf> = ({itemId, picUrl, title, type, price, currency}) => {
    const navigate = useNavigate();

    const checkoutClick = useCallback( () => {
        navigate(`/checkout/${itemId}`);
    }, [navigate, itemId]);

    return (
        <Flex direction={"row"} background={"white"} my={6} p={4} borderRadius={"xl"} justifyContent={"space-between"} alignItems="center">
            <Box mt="auto">
                <Avatar size="md" src={picUrl} />
            </Box>
            <Box fontSize="2xl" fontWeight="bold" >
                {price.toFixed(2)} {currency}
            </Box>
            <Box fontSize="2xl" fontWeight="semibold" color="#A3AED0">
                {title}
            </Box>
            <Box fontSize="md" color="#D9D9D9" >
                per {type}
            </Box>
            <Box>
                <Button
                mt="auto"
                bg="brand.50"
                _hover={{ bg: "brand.100" }}
                color="white"
                onClick={checkoutClick}
                >Checkout</Button>
            </Box>
        </Flex>
    );

}