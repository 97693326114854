import React from 'react';
import './App.css';
import {
    ThemeProvider,
    extendTheme,
    ColorModeProvider,
    CSSReset,
    ChakraProvider
} from '@chakra-ui/react'

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

// Pages
import Login from "./Components/Login/Login";
import Profile from "./Components/Profile/Profile";
import Home from "./Components/Home/Home";
import SidebarLayout from "./layouts/SidebarLayout";
import ErrorPage from "./ErrorPage";
import SimpleLayout from "./layouts/SimpleLayout";
import {APIProvider} from '@vis.gl/react-google-maps';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    defaults
} from 'chart.js';
import MemberDetails from "./Components/MemberDetails/MemberDetails";
import MemberList from "./Components/MemberList/MemberList";
import StaffList from "./Components/StaffList/StaffList";
import ProgramsList from "./Components/ProgramsList/ProgramsList";
import SubsidiaryList from "./Components/SubsidaryList/SubsidiaryList";
import Report from "./Components/Report/Report";
import { AuthProvider } from './Services/AuthConext';
import ProtectedRoute from './Services/ProtectedRoute';
import UnauthorizedPage from './Components/Unauthorized/UnauthorizedPage';

import { UserRole } from './Types/UserRoleEnum';
import InvitationComponent from './Components/Invitations/Invitations';
import { StaffDetailComponent } from './Components/StaffList/StaffDetails';
import { ProgramDetail } from './Components/ProgramsList/ProgramDetails';
import { SubsidiaryDetails } from './Components/SubsidaryList/SubsidiaryDetails';
import Success from './Components/Payment/Success';
import Failure from './Components/Payment/Failure';
import MySubscriptions from './Components/Subscriptions/MySubscriptions';
import { CheckoutView } from './Components/Checkout/ChekoutView';
import { SubsidiariesSearchView } from './Components/SubsidaryList/SubsidiarySearchView';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

defaults.maintainAspectRatio=false;
defaults.responsive = true;

const theme = extendTheme({
    colors: {
        brand: {
            50: "#F96E0A",
            100: "#dc640d",
            500: "#CE5A09", // you need this
        },
        background: {
            50: "#F8F6F6"
        },
        success: {
            50: "#33B249",
            100: "#2a9640",
            500: "#217a33"
        },
        gray: {
            50: "#8992af",
            100: "#6f788d",
            500: "#1B2559"
        },
        tableColor: {
            50: "#F7FAFC",
            100: "#EDF2F7",
            500: "#bdb7b7"
        }
    }
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <SidebarLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: (
                    <ProtectedRoute roles={[UserRole.User]}>
                        <Home />
                    </ProtectedRoute>
                )
            },
            {
                path: "profile",
                element: (
                    <ProtectedRoute roles={[UserRole.User]}> 
                        <Profile /> 
                    </ProtectedRoute>
                )
            },
            {
                path: "members/:id",
                element: (
                    <ProtectedRoute roles={[UserRole.User]}>
                        <MemberDetails />
                    </ProtectedRoute>
                )
            },
            {
                path: "members",
                element: <ProtectedRoute roles={[UserRole.User]}><MemberList /></ProtectedRoute>
            },
            {
                path: "staff",
                element: <ProtectedRoute roles={[UserRole.User]}><StaffList /></ProtectedRoute>
            },
            {
                path: "staff/:id",
                element: <ProtectedRoute roles={[UserRole.User]}><StaffDetailComponent /></ProtectedRoute>
            },
            {
                path: "programs",
                element: <ProtectedRoute roles={[UserRole.User]}><ProgramsList /></ProtectedRoute>
            },
            {
                path: "programs/:id",
                element: <ProtectedRoute roles={[UserRole.User]}><ProgramDetail /></ProtectedRoute>
            },
            {
                path: "subsidaries",
                element: <ProtectedRoute roles={[UserRole.User]}><SubsidiaryList /></ProtectedRoute>
            },
            {
                path: "subsidaries/:id",
                element: <ProtectedRoute roles={[UserRole.User]}><SubsidiaryDetails /></ProtectedRoute>
            },
            {
                path: "report",
                element: <ProtectedRoute roles={[UserRole.User]}><Report /></ProtectedRoute>
            },
            {
                path: "invitations",
                element: <ProtectedRoute roles={[UserRole.User]}><InvitationComponent /></ProtectedRoute>
            },
            {
                path: "subscriptions",
                element: <ProtectedRoute roles={[UserRole.User]}><MySubscriptions /></ProtectedRoute>
            },
            {
                path: "success",
                element: <ProtectedRoute roles={[UserRole.User]}><Success /></ProtectedRoute>
            },
            {
                path: "onboarding/success",
                element: <ProtectedRoute roles={[UserRole.User]}><Success /></ProtectedRoute>
            },
            {
                path: "onboarding/refresh",
                element: <ProtectedRoute roles={[UserRole.User]}><Failure /></ProtectedRoute>
            },
            {
                path: "failure",
                element: <ProtectedRoute roles={[UserRole.User]}><Failure /></ProtectedRoute>
            },
            {
                path: "checkout/:id",
                element: <ProtectedRoute roles={[UserRole.User]}><CheckoutView /></ProtectedRoute>
            },
            {
                path: "search",
                element: <ProtectedRoute roles={[UserRole.User]}><SubsidiariesSearchView /></ProtectedRoute>
            }
        ]
    },
    {
        path: "/login",
        element: <SimpleLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Login />
            }
        ]
    },
    {
        path: "unauthorized",
        element: <SimpleLayout />,
        errorElement: <ErrorPage />,
        children: 
        [
            {
                path: "",
                element: <UnauthorizedPage />
            }
        ]
    }
]);

function App() {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY !== undefined ? process.env.REACT_APP_GOOGLE_API_KEY : ''
  return (
    <ChakraProvider>
        
      <ThemeProvider theme={theme}>
          <ColorModeProvider>
            <CSSReset />
            <APIProvider apiKey={apiKey} onLoad={() => console.log('Maps API has loaded.')}>
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
            </APIProvider>
          </ColorModeProvider>
      </ThemeProvider>
      </ChakraProvider>
  );
}

export default App;
